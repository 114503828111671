import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_URL } from '../../config/config';

/**
 * Api routes for the users service
 */
@Injectable()
export class ContactsApiService {

    /** base url where our server is */
    baseUrl: string = API_URL;

    /**
     * Constructor to bring in the http client
     * @param http - angular http client
     */
    constructor(
        private http: HttpClient
    ) { }

    // contacts
    postContact(toSend, jobId, type) {
        return this.http.post(this.baseUrl + `/contacts/${type}/${jobId}`, toSend);
    }

    deleteContact(contactId, type) {
        return this.http.delete(this.baseUrl + `/contacts/${type}/${contactId}`);
    }

    getContacts(jobId, type) {
        return this.http.get(this.baseUrl + `/contacts/${type}/${jobId}`);
    }

    putContact(contactId, contactInfo, type) {
        return this.http.put(this.baseUrl + `/contacts/${type}/${contactId}`, contactInfo);
    }

}
