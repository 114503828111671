import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';
import { TalentsApiService } from 'src/app/services';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface DialogData {
    filterValues: any;
}

/**
 * @title Dialog Overview
 */
@Component({
    selector: 'app-add-talent-dialog',
    templateUrl: 'add.talent.dialog.html',
    styleUrls: ['add.talent.dialog.scss'],
})
export class AddTalentDialog {

    talentFormControl = {
        firstName: new FormControl('', [Validators.required]),
        lastName: new FormControl('', [Validators.required]),
        dob: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.required, Validators.email]),
        phone: new FormControl('', []),
        gender: '',
        ethnicity: [''],
        hair: '',
        eyes: '',
        jacketShoulder: new FormControl('', []),
        jacketLength: new FormControl('', []),
        neck: new FormControl('', []),
        sleeve: new FormControl('', []),
        heightFeet: new FormControl('', []),
        heightInches: new FormControl('', []),
        shoe: new FormControl('', []),
        bust: new FormControl('', []),
        waist: new FormControl('', []),
        inseam: new FormControl('', []),
        hips: new FormControl('', []),
        instagram: new FormControl('', []),
        kidsSize: '',
        contactRelationship: new FormControl('', []),
        contactName: new FormControl('', [Validators.required]),
        dress: '',
        office: new FormControl([], [Validators.required])
    };

    forSomeoneElse = false;
    talentOrganizations: any = [];

    constructor(
        public dialogRef: MatDialogRef<AddTalentDialog>,
        private talentsApi: TalentsApiService,
        private snackBar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) public data: DialogData) {
          this.talentsApi.getOrganizations('talent').subscribe(results => {
            this.talentOrganizations = results;
          });
        }

    onNoClick(): void {
        this.dialogRef.close();
    }

    getAge() {
        if (!this.talentFormControl.dob) {
            return false;
        }
        const today = new Date();
        const birthDate = new Date(this.talentFormControl.dob.value);
        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age < 14;
    }

    saveTalent() {
        let toSend = {};
        const height = Number(this.talentFormControl.heightFeet.value) * 12 + Number(this.talentFormControl.heightInches.value);
        let sizeType;
        if (this.getAge()) {
          sizeType = 'Kids';
        } else {
          sizeType = 'Adult';
        }
        if (this.talentFormControl.gender === 'male') {
            toSend = {
                firstName: this.talentFormControl.firstName.value,
                lastName: this.talentFormControl.lastName.value,
                email: this.talentFormControl.email.value,
                gender: this.talentFormControl.gender,
                ethnicity: this.talentFormControl.ethnicity,
                hair: this.talentFormControl.hair,
                eyes: this.talentFormControl.eyes,
                height,
                phone: this.talentFormControl.phone.value,
                neck: this.talentFormControl.neck.value === '' ? null : this.talentFormControl.neck.value,
                shoe: this.talentFormControl.shoe.value === '' ? null : this.talentFormControl.shoe.value,
                waist: this.talentFormControl.waist.value === '' ? null : this.talentFormControl.waist.value,
                inseam: this.talentFormControl.inseam.value === '' ? null : this.talentFormControl.inseam.value,
                jacket: { "sleeve": this.talentFormControl.sleeve.value === '' ? null : this.talentFormControl.sleeve.value,
                    "shoulder": this.talentFormControl.jacketShoulder.value === '' ? null : this.talentFormControl.jacketShoulder.value,
                    "length": this.talentFormControl.jacketLength.value === '' ? null : this.talentFormControl.jacketLength.value },
                socialLinks: {},
                dob: this.talentFormControl.dob.value,
                kidsSize: this.talentFormControl.kidsSize === '' ? null : this.talentFormControl.kidsSize,
                contactName: this.talentFormControl.contactName.value,
                contactRelationship: this.talentFormControl.contactRelationship.value,
                parent: this.talentFormControl.office.value,
                sizeType
            };
        } else {
            toSend = {
                firstName: this.talentFormControl.firstName.value,
                lastName: this.talentFormControl.lastName.value,
                email: this.talentFormControl.email.value,
                gender: this.talentFormControl.gender,
                ethnicity: this.talentFormControl.ethnicity,
                hair: this.talentFormControl.hair,
                eyes: this.talentFormControl.eyes,
                height,
                phone: this.talentFormControl.phone.value,
                bust: this.talentFormControl.bust.value === '' ? null : this.talentFormControl.bust.value,
                neck: this.talentFormControl.neck.value === '' ? null : this.talentFormControl.neck.value,
                shoe: this.talentFormControl.shoe.value === '' ? null : this.talentFormControl.shoe.value,
                dress: this.talentFormControl.dress,
                waist: this.talentFormControl.waist.value === '' ? null : this.talentFormControl.waist.value,
                hips: this.talentFormControl.hips.value === '' ? null : this.talentFormControl.hips.value,
                inseam: null,
                jacket: {},
                socialLinks: {},
                dob: this.talentFormControl.dob.value,
                kidsSize: this.talentFormControl.kidsSize === '' ? null : this.talentFormControl.kidsSize,
                contactName: this.talentFormControl.contactName.value,
                contactRelationship: this.talentFormControl.contactRelationship.value,
                parent: this.talentFormControl.office.value,
                sizeType
            };
        }
        this.talentsApi.postTalent(toSend).subscribe(result => {
            this.snackBar.open('Successfully added talent', '', { duration: 1000 });
            this.dialogRef.close(true);
        }, error => {
            console.log('error', error);
            this.snackBar.open('Error adding talent', '', { duration: 1000 });
            this.dialogRef.close(false);
        });
    }
}
