import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface DialogData {
    titleText: string;
    contentText: string;
    submitText: string;
    dismissText: string;
}

/**
 * @title Dialog Overview
 */
@Component({
    selector: 'app-dialog',
    templateUrl: 'dialog.component.html',
    styleUrls: ['dialog.component.scss'],
})
export class AppDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<AppDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

    onNoClick(e): void {
        this.dialogRef.close(e);
    }
}
