import { Component } from '@angular/core';
/**
 * Base app component
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  /** title of our app */
  title = 'repd';
}
