<h1 mat-dialog-title *ngIf="!data.activeJob">{{data?.editContact ? 'Edit' : 'Add'}} a Contact for
    {{data?.activeTalent?.firstName}}
</h1>
<h1 *ngIf="data.activeJob">{{data?.editContact ? 'Edit Contact' : 'Add Contact'}}</h1>
<div mat-dialog-content>
    <div class="form-field-row">
        <mat-form-field class="form-field form-field-half" appearance="outline">
            <mat-label>Contact Name</mat-label>
            <input class="email-input" [formControl]="contactFormControl.contactName" matInput required>
            <mat-error *ngIf="contactFormControl.contactName.hasError('required')">
                Contact name is required.
            </mat-error>
        </mat-form-field>
        <mat-form-field class="form-field form-field-half" style="margin-left: 16px" appearance="outline">
            <mat-label>Email</mat-label>
            <input class="email-input" [formControl]="contactFormControl.email" matInput>
            <mat-error *ngIf="contactFormControl.email.hasError('email')">
                Please enter a valid email.
            </mat-error>
        </mat-form-field>
    </div>
    <div class="form-field-row">
        <mat-form-field class="form-field form-field-half" appearance="outline">
            <mat-label>Phone</mat-label>
            <input type="tel" class="email-input" [formControl]="contactFormControl.phone" matInput>
        </mat-form-field>
    </div>
    <!-- have a toggle for is this the primary contact -->
    <div class="form-field-row">
        <mat-slide-toggle [(ngModel)]="contactFormControl.isPrimaryContact" labelPosition="before"
            *ngIf="(!data.activeJob && !contactFormControl.isPrimaryContact) || !data.editContact">Is this the primary
            contact?
        </mat-slide-toggle>
    </div>
    <div class="form-field-row">
        <mat-form-field class="form-field form-field-half" appearance="outline">
            <mat-label>Relationship/Role</mat-label>
            <input class="email-input" [formControl]="contactFormControl.relationship" matInput>
        </mat-form-field>
    </div>
    <div class="form-field-row">
        <mat-form-field class="form-field form-field-half" appearance="outline">
            <mat-label>Address 1</mat-label>
            <input class="email-input" [formControl]="contactFormControl.address1" matInput>
        </mat-form-field>
        <mat-form-field class="form-field form-field-half" style="margin-left: 16px" appearance="outline">
            <mat-label>Address 2</mat-label>
            <input class="email-input" [formControl]="contactFormControl.address2" matInput>
        </mat-form-field>
    </div>

    <div class="form-field-row">
        <mat-form-field class="form-field form-field-half" appearance="outline">
            <mat-label>City</mat-label>
            <input class="email-input" [formControl]="contactFormControl.city" matInput>
        </mat-form-field>
        <mat-form-field class="form-field form-field-half" style="margin-left: 16px" appearance="outline">
            <mat-label>State</mat-label>
            <input class="email-input" [formControl]="contactFormControl.state" matInput>
        </mat-form-field>
    </div>
    <div class="form-field-row">
        <mat-form-field class="form-field form-field-half" appearance="outline">
            <mat-label>Zip</mat-label>
            <input class="email-input" [formControl]="contactFormControl.zip" matInput>
        </mat-form-field>
    </div>
</div>
<div mat-dialog-actions align="end">
    <button mat-raised-button (click)="onNoClick()" color="secondary">Cancel</button>
    <button mat-raised-button (click)="saveContact()" color="primary">{{data.editContact ? 'Save' : 'Submit'}}</button>
</div>
