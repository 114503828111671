import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * Layout service to check if the user is mobile etc.
 */
@Injectable()
export class LayoutService {

  /** the users device */
  device = 'desktop';
  /** observable to handle if the device changed. */
  deviceSizeChanged$: Subject<string> = new Subject<string>();

  /**
   * Constructor calls to get the width
   */
  constructor() {
    this.width();
  }

  /**
   * Returns the device of the user.
   */
  width(): void {
    const elem = (document.compatMode === 'CSS1Compat') ?
      document.documentElement :
      document.body;

    const width = elem.clientWidth;

    if (width >= 992) {
      this.device = 'desktop';
    }
    if (width < 992) {
      this.device = 'tablet';
    }
    if (width < 768) {
      this.device = 'mobile';
    }
  }

  /**
   * The device got resized and we can reemit that.
   */
  resize(): void {
    const previousDevice = this.device;
    this.width();

    if (this.device === previousDevice) {
      return;
    }

    this.deviceSizeChanged$.next(this.device);
  }
}
