import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';
import { ContactsApiService } from 'src/app/services';

export interface DialogData {
    activeTalent: any;
    activeJob: any;
    editContact: any;
    activeContact: any;
    isOrganization?: boolean;
    type: string;
}

/**
 * @title Dialog Overview
 */
@Component({
    selector: 'app-add-contact-dialog',
    templateUrl: 'add.contact.html',
    styleUrls: ['add.contact.scss'],
})
export class AddContactDialog implements OnInit {

    contactFormControl = {
        contactName: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.email]),
        phone: new FormControl(''),
        relationship: new FormControl(''),
        isPrimaryContact: true,
        address1: new FormControl(''),
        address2: new FormControl(''),
        city: new FormControl(''),
        state: new FormControl(''),
        zip: new FormControl('')
    };

    constructor(
        public dialogRef: MatDialogRef<AddContactDialog>,
        private contactsApi: ContactsApiService,
        @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

    onNoClick(): void {
        this.dialogRef.close();
    }

    ngOnInit() {
        if (this.data.editContact) {
            this.contactFormControl = {
                contactName: new FormControl(this.data.activeContact.contactName, [Validators.required]),
                email: new FormControl(this.data.activeContact.email, [Validators.email]),
                phone: new FormControl(this.data.activeContact.phone),
                relationship: new FormControl(this.data.activeContact.contactRelationship),
                isPrimaryContact: this.data.activeContact.contactType === 'PRIMARY',
                address1: new FormControl(this.data.activeContact.location.address1),
                address2: new FormControl(this.data.activeContact.location.address2),
                city: new FormControl(this.data.activeContact.location.city),
                state: new FormControl(this.data.activeContact.location.state),
                zip: new FormControl(this.data.activeContact.location.zip)
            };
        }
    }

    saveJobContact(toSend) {


        const httpReq = this.data.editContact ?
            this.contactsApi.putContact(this.data.activeContact.id, toSend, this.data.type) :
            this.contactsApi.postContact(toSend, this.data.activeJob.id, this.data.type);


        if (this.data.editContact) {
            httpReq.subscribe(() => {
                this.dialogRef.close(true);
            }, error => {
                console.log('error', error);
                this.dialogRef.close(false);
            });
        } else {
            httpReq.subscribe(() => {
                this.dialogRef.close(true);
            }, error => {
                console.log('error', error);
                this.dialogRef.close(false);
            });
        }
    }

    saveContact() {

        const toSend = {
            contactName: this.contactFormControl.contactName.value,
            email: this.contactFormControl.email.value,
            phone: this.contactFormControl.phone.value,
            contactType: this.contactFormControl.isPrimaryContact ? 'PRIMARY' : 'SECONDARY',
            contactRelationship: this.contactFormControl.relationship.value,
            location: {
                zip: this.contactFormControl.zip.value,
                city: this.contactFormControl.city.value,
                state: this.contactFormControl.state.value,
                address1: this.contactFormControl.address1.value,
                address2: this.contactFormControl.address2.value,
            }
        };
        // if we are on active job then we go to otehr
        if (this.data.activeJob) {
            this.saveJobContact(toSend);
            return;
        }


        if (this.data.editContact) {
            this.contactsApi.putContact(this.data.activeContact.id, toSend, this.data.type).subscribe(result => {
                this.dialogRef.close(true);
            }, error => {
                console.log('error', error);
                this.dialogRef.close(false);
            });
        } else {
            this.contactsApi.postContact(toSend, this.data.activeTalent.id, this.data.type).subscribe(result => {
                this.dialogRef.close(true);
            }, error => {
                console.log('error', error);
                this.dialogRef.close(false);
            });
        }
    }
}
