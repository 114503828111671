export const BASE_URL = 'https://staging-admin.repd.co';
export const REPD_BASE_URL = 'https://staging-app.repd.co/';
export const API_URL = 'https://staging-admin.repd.co/api';

export const AUTH_WEB = {
    domain: 'dev-tmay8ff9.us.auth0.com',
    clientID: 'P49NvgD6qhjF9sXDtmJIxkmGA8qjrW0l',
    responseType: 'token',
    realm: 'Username-Password-Staging'
};
export const AUTH_SOCIAL_WEB = {
    domain: 'dev-tmay8ff9.us.auth0.com',
    clientID: 'P49NvgD6qhjF9sXDtmJIxkmGA8qjrW0l',
    responseType: 'id_token',
    redirectUri: BASE_URL + '/profile',
    realm: 'Username-Password-Staging'
};
