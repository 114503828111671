import { JwtModule } from '@auth0/angular-jwt';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LayoutService } from './shared/layout.service';
import { InterceptService } from './shared/http.interceptor';
import { UsersApiService } from './api/users.api.service';
import { TalentsApiService } from './api/talents.api.service';
import { JobsApiService } from './api/jobs.api.service';
import { ContactsApiService } from './api/contacts.api.service';

/**
 * Get the JWT token
 */
export function tokenGetter() {
    return localStorage.getItem('idToken');
}

@NgModule({
    imports: [
        HttpClientModule,
        JwtModule.forRoot({
            config: {
                tokenGetter,
                allowedDomains: ['localhost:3000', 'repd.dev.dos.sh', 'repdadmin.dev.dos.sh', 'admin.repd.co', 'api.repd.test'],
                disallowedRoutes: []
            }
        }),
    ],
    declarations: [],
    exports: []
})

/**
 * The shared service that we can apply to root.
 */
export class SharedServiceModule {
    static forRoot(): ModuleWithProviders<SharedServiceModule> {
        return {
            ngModule: SharedServiceModule,
            providers: [
                LayoutService,
                UsersApiService,
                JobsApiService,
                InterceptService,
                ContactsApiService,
                TalentsApiService,
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: InterceptService,
                    multi: true
                }
            ]
        };
    }
}
export * from './shared/layout.service';
export * from './api/users.api.service';
export * from './api/talents.api.service';
export * from './api/jobs.api.service';
export * from './api/contacts.api.service';
