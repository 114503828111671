


import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_URL } from '../../config/config';

/**
 * Api routes for the users service
 */
@Injectable()
export class TalentsApiService {

    /** base url where our server is */
    baseUrl: string = API_URL;

    /**
     * Constructor to bring in the http client
     * @param http - angular http client
     */
    constructor(
        private http: HttpClient
    ) { }

    getTalents() {
        return this.http.get(this.baseUrl + '/talent');
    }

    getTalentById(id) {
        return this.http.get(this.baseUrl + `/talent/${id}`);
    }

    getTalentUserInfo(id) {
        return this.http.get(this.baseUrl + `/talent/${id}/user`);
    }

    getTalentPortfolios(id) {
        return this.http.get(this.baseUrl + `/talent/${id}/portfolios`);
    }

    getTalentWithPorfolios() {
        return this.http.get(this.baseUrl + `/talent?portfolio=true`);
    }

    postTalent(toSend) {
        return this.http.post(this.baseUrl + `/talent`, toSend);
    }


    putTalent(id, toSend) {
        return this.http.put(this.baseUrl + `/talent/${id}`, toSend);
    }

    deleteTalent(id) {
        return this.http.delete(this.baseUrl + `/talent/${id}`);
    }

    deleteMedia(talentId, mediaId) {
        return this.http.delete(this.baseUrl + `/talent/${talentId}/media/${mediaId}`);
    }

    putTalentContact(contactId, contactInfo) {
        return this.http.put(this.baseUrl + `/contacts/TALENT/${contactId}`, contactInfo);
    }

    deleteTalentPortfolio(talentId, folioId) {
        return this.http.delete(this.baseUrl + `/talent/${talentId}/portfolios/${folioId}`);
    }

    getTalentMedia(talentId) {
        return this.http.get(this.baseUrl + `/talent/${talentId}/media`);
    }

    getPortfolioById(talentId, portfolioId) {
        return this.http.get(this.baseUrl + `/talent/${talentId}/portfolios/${portfolioId}`);
    }

    savePortfolio(talentId, portfolioId, toSend) {
        return this.http.put(this.baseUrl + `/talent/${talentId}/portfolios/${portfolioId}`, toSend);
    }

    addPortfolio(talentId, toSend) {
        return this.http.post(this.baseUrl + `/talent/${talentId}/portfolios`, toSend);
    }

    getTags() {
        return this.http.get(this.baseUrl + `/tags`);
    }

    getOrganizations(type: string) {
        const url = type ? this.baseUrl + `/organizations?type=${type}` : this.baseUrl + `/organizations`;
        return this.http.get(url);
    }

    getOrganizationById(id) {
        return this.http.get(this.baseUrl + `/organizations/${id}`);
    }

    createOrganization(toSend) {
        return this.http.post(this.baseUrl + `/organizations`, toSend);
    }

    updateOrganization(toSend, id) {
        return this.http.put(this.baseUrl + `/organizations/${id}`, toSend);
    }

    saveTalentMedia(formDataMedia: FormData, talentId) {
        return this.http.post(this.baseUrl + `/talent/${talentId}/media`, formDataMedia);
    }

    getAllPortfolios() {
        return this.http.get(this.baseUrl + `/portfolios/all`);
    }

    archiveOffice(office) {
        return this.http.put(this.baseUrl + `/archiveOffice`, office);
    }



}
