import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_URL } from '../../config/config';

/**
 * Api routes for the users service
 */
@Injectable()
export class JobsApiService {

    /** base url where our server is */
    baseUrl: string = API_URL;

    /**
     * Constructor to bring in the http client
     * @param http - angular http client
     */
    constructor(
        private http: HttpClient
    ) { }

    /**
     * POST the user to register
     * @param user - the user we are registering
     */
    getJobs() {
        return this.http.get(this.baseUrl + '/job');
    }

    duplicateJob(id) {
        return this.http.get(`${this.baseUrl}/job/${id}/duplicate`);
    }

    // /need/{id}/media
    addCastingMedia(id, payload) {
        return this.http.post(this.baseUrl + `/need/${id}/media`, payload);
    }

    // /portfolio/{casting portfolio id}
    addCastingPortfolio(id, payload) {
        return this.http.put(this.baseUrl + `/portfolio/${id}`, payload);
    }

    getJobById(id) {
        return this.http.get(this.baseUrl + `/job/${id}`);
    }

    putJob(id, toSend) {
        return this.http.put(this.baseUrl + `/job/${id}`, toSend);
    }

    postJob(toSend) {
        return this.http.post(this.baseUrl + `/job`, toSend);
    }

    getJobsNeeds(id) {
        return this.http.get(this.baseUrl + `/job/${id}/need`);
    }


    deleteNeed(jobId, needId) {
        return this.http.delete(this.baseUrl + `/job/${jobId}/need/${needId}`);
    }

    addJobNeed(toSend, jobId) {
        return this.http.post(this.baseUrl + `/job/${jobId}/need`, toSend);
    }

    putNeedToJob(jobId, needId, toSend) {
        return this.http.put(this.baseUrl + `/job/${jobId}/need/${needId}`, toSend);
    }

    updatePublicOffer(needId) {
        return this.http.get(this.baseUrl + `/public/need/${needId}/status`);
    }

    deleteCastingMedia(needId, offerId) {
        return this.http.delete(this.baseUrl + `/need/${needId}/offers/${offerId}`);
    }

}
