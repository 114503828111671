import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from './components/404';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./modules/login/index').then(mod => mod.LoginModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./modules/profile/index').then(mod => mod.ProfileModule)
  },
  {
    path: 'talents',
    loadChildren: () => import('./modules/talents/index').then(mod => mod.TalentsModule)
  },
  {
    path: 'organizations',
    loadChildren: () => import('./modules/agencies/index').then(mod => mod.AgenicesModule)
  },
  {
    path: 'jobs',
    loadChildren: () => import('./modules/jobs/index').then(mod => mod.JobsModule)
  },
  {
    path: 'notFound',
    component: NotFoundComponent
  },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
