import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';

/**
 * HTTP interceptor to handle 401's and redirect them back to login
 */
@Injectable()
export class InterceptService implements HttpInterceptor {

    /**
     * Constructor
     * @param router - the angular router to navigate
     */
    constructor(
        private router: Router
    ) { }

    /**
     * Intercept all responses to check for 401
     * @param request - the http request
     * @param next - http handler
     */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(
                tap(event => { }, error => {
                    if (error.status === 401) {
                        // route back to login
                        this.router.navigate(['/login']);
                    }
                    if (error.status === 410) {
                        this.router.navigate(['/notFound']);
                    }
                })
            );
    }
}
