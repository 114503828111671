import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_URL } from '../../config/config';

/**
 * Api routes for the users service
 */
@Injectable()
export class UsersApiService {

    /** base url where our server is */
    baseUrl: string = API_URL;

    /**
     * Constructor to bring in the http client
     * @param http - angular http client
     */
    constructor(
        private http: HttpClient
    ) { }

    /**
     * POST the user to register
     * @param user - the user we are registering
     */
    registerUser(user) {
        return this.http.post(this.baseUrl + '/users/register', user);
    }

    /**
     * POST the new admin user
     * @param user - the user we are registering
     */
    postUser(user) {
      return this.http.post(this.baseUrl + '/user', user);
    }

    /**
     * Get the users profile
     */
    getMyUser() {
        return this.http.get(this.baseUrl + '/users/profile');
    }

    /**
     * Get all users
     */
    getAllUsers() {
        return this.http.get(this.baseUrl + '/users');
    }

    /**
     * Get admin users
     */
    getAdminUsers() {
        return this.http.get(this.baseUrl + '/users/admin');
    }

    getOfficeDetails() {
        return this.http.get(this.baseUrl + '/office/details')
    }

    getLookups() {
        return this.http.get(this.baseUrl + '/lookups');
    }

    /**
     * Update my user
     * @param user - the user we are updating
     */
    updateUser(user) {
        return this.http.put(this.baseUrl + '/users/profile', user);
    }

    updateAdminUser(adminUser, userId) {
        return this.http.put(this.baseUrl + `/users/${userId}`, adminUser)
    }
}
