<h1>Add Talent</h1>
<div mat-dialog-content>
    <div class="form-field-row">
        <mat-form-field class="form-field form-field-half" appearance="outline">
            <mat-label>First Name</mat-label>
            <input class="email-input" [formControl]="talentFormControl.firstName" matInput required>
            <mat-error *ngIf="talentFormControl.firstName.hasError('required')">
                First name is required.
            </mat-error>
        </mat-form-field>
        <mat-form-field style="margin-left: 16px" class="form-field form-field-half" appearance="outline">
            <mat-label>Last Name</mat-label>
            <input class="email-input" [formControl]="talentFormControl.lastName" matInput required>
            <mat-error *ngIf="talentFormControl.lastName.hasError('required')">
                Last name is required.
            </mat-error>
        </mat-form-field>
    </div>
    <div class="form-field-row">
        <mat-form-field class="form-field form-field-half" appearance="outline">
            <mat-label>Date of birth</mat-label>
            <input matInput [formControl]="talentFormControl.dob" [matDatepicker]="dobPicker" required>
            <mat-datepicker-toggle matSuffix [for]="dobPicker"></mat-datepicker-toggle>
            <mat-datepicker #dobPicker></mat-datepicker>
            <mat-error *ngIf="talentFormControl.dob.hasError('required')">
                Date of birth is required.
            </mat-error>
        </mat-form-field>
        <mat-form-field class="form-field form-field-half" style="margin-left:16px" appearance="outline">
            <mat-select [(ngModel)]="talentFormControl.gender" placeholder="Gender *" required>
                <mat-option value="male">Male</mat-option>
                <mat-option value="female">Female</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="form-field-row">
      <mat-form-field class="form-field form-field-half" appearance="outline">
          <mat-select [(formControl)]="talentFormControl.office" placeholder="Office *" matInput required>
              <mat-option *ngFor="let option of talentOrganizations" [value]="option.id">
                {{option.name}}</mat-option>
          </mat-select>
          <mat-error *ngIf="talentFormControl.office.hasError('required')">
            Office is required.
        </mat-error>
      </mat-form-field>
  </div>
    <div class="form-field-row">
        <mat-slide-toggle [(ngModel)]="forSomeoneElse" labelPosition="before">Does this Talent have a different primary
            contact, like a parent?</mat-slide-toggle>
    </div>
    <div class="form-field-row">
        <mat-form-field class="form-field form-field-half" appearance="outline">
            <mat-label>Email</mat-label>
            <input class="email-input" [formControl]="talentFormControl.email" required matInput>
            <mat-error *ngIf="talentFormControl.email.hasError('email')">
                Please enter a valid email.
            </mat-error>
        </mat-form-field>
        <mat-form-field style="margin-left: 16px" class="form-field form-field-half" appearance="outline">
            <mat-label>Phone</mat-label>
            <input type="tel" class="email-input" [formControl]="talentFormControl.phone" matInput>
        </mat-form-field>
    </div>
    <div class="form-field-row" *ngIf="forSomeoneElse">
        <mat-form-field class="form-field form-field-half" appearance="outline">
            <mat-label>Contact Name</mat-label>
            <input class="email-input" [formControl]="talentFormControl.contactName" matInput required>
            <mat-error *ngIf="talentFormControl.contactName.hasError('required')">
                Contact name is required.
            </mat-error>
        </mat-form-field>
        <mat-form-field style="margin-left: 16px" class="form-field form-field-half" appearance="outline">
            <mat-label>Contact Relationship</mat-label>
            <input class="email-input" [formControl]="talentFormControl.contactRelationship" matInput required>
            <mat-error *ngIf="talentFormControl.contactRelationship.hasError('required')">
                Contact relationship is required.
            </mat-error>
        </mat-form-field>
    </div>
    <div class="form-field-row">
        <mat-form-field class="form-field form-field-half" appearance="outline">
            <mat-label>Height - Feet</mat-label>
            <input type="tel" class="email-input" [formControl]="talentFormControl.heightFeet" matInput>
        </mat-form-field>

        <mat-form-field class="form-field form-field-half" style="margin-left: 16px" appearance="outline">
            <mat-label>Height - Inches</mat-label>
            <input type="tel" class="email-input" [formControl]="talentFormControl.heightInches" matInput>
        </mat-form-field>
    </div>
    <div class="form-field-row">
        <mat-form-field class="form-field form-field-half" appearance="outline">
            <mat-select [(ngModel)]="talentFormControl.hair" placeholder="Hair">
                <mat-option></mat-option>
                <mat-option *ngFor="let option of data.filterValues.lookup_hair_color" [value]="option.name">
                    {{option.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="form-field form-field-half" style="margin-left: 16px" appearance="outline">
            <mat-select [(ngModel)]="talentFormControl.eyes" placeholder="Eyes">
                <mat-option></mat-option>
                <mat-option *ngFor="let option of data.filterValues.lookup_eye_color" [value]="option.name">
                    {{option.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="form-field-row">
        <mat-form-field class="form-field form-field-half" appearance="outline" hideRequiredMarker>
            <mat-label>Shoe</mat-label>
            <input type=tel class="email-input" [formControl]="talentFormControl.shoe" matInput required>
        </mat-form-field>
        <mat-form-field class="form-field form-field-half" style="margin-left: 16px" appearance="outline">
            <mat-select [(ngModel)]="talentFormControl.ethnicity" placeholder="Ethnicity" multiple>
                <mat-option *ngFor="let option of data.filterValues.lookup_ethnicity" [value]="option.name">
                    {{option.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <ng-container *ngIf="talentFormControl.gender === 'male'">
        <!-- TODO: these should have a hint to them to tell the user how to input -->
        <div class="form-field-row">
            <mat-form-field class="form-field form-field-half" appearance="outline" hideRequiredMarker>
                <mat-label>Jacket Shoulder</mat-label>
                <input type=tel class="email-input" [formControl]="talentFormControl.jacketShoulder" matInput required>
            </mat-form-field>
            <mat-form-field class="form-field form-field-half" style="margin-left: 16px" appearance="outline"
                hideRequiredMarker>
                <mat-label>Length</mat-label>
                <input type=tel class="email-input" [formControl]="talentFormControl.jacketLength" matInput required>
            </mat-form-field>
        </div>

        <div class="form-field-row">
            <mat-form-field class="form-field form-field-half" appearance="outline" hideRequiredMarker>
                <mat-label>Neck</mat-label>
                <input type=tel class="email-input" [formControl]="talentFormControl.neck" matInput required>
            </mat-form-field>
            <mat-form-field class="form-field form-field-half" style="margin-left: 16px" appearance="outline"
                hideRequiredMarker>
                <mat-label>Sleeve</mat-label>
                <input type=tel class="email-input" [formControl]="talentFormControl.sleeve" matInput required>
            </mat-form-field>
        </div>

        <div class="form-field-row">
            <mat-form-field class="form-field form-field-half" appearance="outline" hideRequiredMarker>
                <mat-label>Waist</mat-label>
                <input type=tel class="email-input" [formControl]="talentFormControl.waist" matInput required>
            </mat-form-field>
            <mat-form-field class="form-field form-field-half" style="margin-left: 16px" appearance="outline"
                hideRequiredMarker>
                <mat-label>Inseam</mat-label>
                <input type=tel class="email-input" [formControl]="talentFormControl.inseam" matInput required>
            </mat-form-field>
        </div>
    </ng-container>
    <ng-container *ngIf="talentFormControl.gender === 'female'">
        <div class="form-field-row">
            <mat-form-field class="form-field form-field-half" appearance="outline" hideRequiredMarker>
                <mat-label>Bust</mat-label>
                <input type=tel class="email-input" [formControl]="talentFormControl.bust" matInput required>
            </mat-form-field>
            <mat-form-field class="form-field form-field-half" style="margin-left: 16px" appearance="outline"
                hideRequiredMarker>
                <mat-label>Waist</mat-label>
                <input type=tel class="email-input" [formControl]="talentFormControl.waist" matInput required>
            </mat-form-field>
        </div>
        <div class="form-field-row">
            <mat-form-field class="form-field form-field-half" appearance="outline" hideRequiredMarker>
                <mat-label>Hips</mat-label>
                <input type=tel class="email-input" [formControl]="talentFormControl.hips" matInput required>
            </mat-form-field>
            <mat-form-field class="form-field form-field-half" style="margin-left: 16px" appearance="outline">
                <mat-select [(ngModel)]="talentFormControl.dress" placeholder="Dress">
                    <mat-option></mat-option>
                    <mat-option *ngFor="let option of data.filterValues.lookup_dress" [value]="option.name">
                        {{option.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </ng-container>
    <ng-container *ngIf="getAge()">
        <mat-form-field class="form-field" appearance="outline" style="width: 100%">
            <mat-select [(ngModel)]="talentFormControl.kidsSize" placeholder="Kids Size">
                <mat-option></mat-option>
                <mat-option *ngFor="let option of data.filterValues.lookup_kids_size" [value]="option.name">
                    {{option.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </ng-container>
</div>
<div mat-dialog-actions align="end">
    <button mat-raised-button (click)="onNoClick()" color="secondary">Cancel</button>
    <button mat-raised-button (click)="saveTalent()" [disabled]="talentFormControl.firstName.hasError('required') ||  talentFormControl.lastName.hasError('required') || talentFormControl.dob.hasError('required')
        || talentFormControl.email.hasError('required') || talentFormControl.office.hasError('required') || talentFormControl.gender === ''" color="primary">Add
        Talent</button>
</div>
