import { Component } from '@angular/core';

/**
 * 404 page not found
 */
@Component({
    selector: 'app-not-found',
    templateUrl: './not.found.component.html',
    styleUrls: ['./not.found.component.scss'],
})
export class NotFoundComponent {

}
